import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Select from 'react-select';
import styled, { keyframes } from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './App.css';

const AppContainer = styled.div`
  font-family: 'Helvetica', sans-serif;
  padding: 20px;
  background-color: #f0f4f8;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-family: 'Helvetica', sans-serif;
  font-size: 3rem;
  color: #1f78d1;
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionContainer = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 2px solid #1f78d1;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const StatCard = styled.div`
  background: white;
  flex: 1;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  min-width: 200px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const StatValue = styled.div`
  font-size: 2rem;
  color: #1f78d1;
  font-weight: bold;
  margin-top: 10px;
`;

const UserStatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const UserStats = styled.div`
  background: white;
  border-radius: 8px;
  flex: 1;
  padding: 20px;
  text-align: center;
  min-width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: #777;
  margin-bottom: 20px;
  text-align: center;
`;

const RefreshAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
`;

const RefreshButton = styled.button`
  background-color: #1f78d1;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  animation: ${({ refreshing }) => (refreshing ? RefreshAnimation : 'none')} 0.5s ease-in-out;
  &:hover {
    background-color: #155a9c;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const RefreshMessage = styled.div`
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CustomTabList = styled(TabList)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #1f78d1;
  flex-wrap: wrap;
`;

const CustomTab = styled(Tab)`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #1f78d1;
  &.react-tabs__tab--selected {
    border: 2px solid #1f78d1;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background-color: white;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 1rem;
  }
`;

const CustomTabs = styled(Tabs)`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th,
  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }

  th {
    background-color: #1f78d1;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f0f4f8;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  background-color: #1f78d1;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f4f8;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #1f78d1;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #155a9c;
  }
`;

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [key, setKey] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // 미리 약속된 사용자 정보
    const users = {
      jay: '63326',
      justin: '63326',
      jina: '63326',
      admin: '63326'
    };

    // 로그인 정보 검증
    if (users[username] === key) {
      onLogin(username); // 성공 시 App 컴포넌트에 로그인 상태를 전달
    } else {
      alert('잘못된 사용자 이름 또는 키입니다.');
    }
  };

  return (
    <LoginContainer>
      <h1>로그인</h1>
      <LoginForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <Button type="submit">로그인</Button>
      </LoginForm>
    </LoginContainer>
  );
};

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsersData, setAllUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalAsset, setTotalAsset] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [intervalOptions, setIntervalOptions] = useState([]);
  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState(0);
  const [selectedIntervalEntries, setSelectedIntervalEntries] = useState([]);
  const [selectedIntervalStats, setSelectedIntervalStats] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchExchangeRate = async () => {
    const lastFetchTime = localStorage.getItem('lastFetchTime');
    const now = new Date();
    const currentTime = now.getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    if (!lastFetchTime || currentTime - lastFetchTime > oneDay) {
      try {
        const response = await axios.get('https://v6.exchangerate-api.com/v6/66cf4adcb23ad4d2cc633587/latest/USD');
        const rate = response.data.conversion_rates.KRW;
        localStorage.setItem('exchangeRate', rate);
        localStorage.setItem('lastFetchTime', currentTime);
        setExchangeRate(rate);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        setExchangeRate(localStorage.getItem('exchangeRate') || null);
      }
    } else {
      setExchangeRate(localStorage.getItem('exchangeRate'));
    }
  };

  const fetchData = async () => {
    setRefreshing(true);
    try {
      const response = await axios.get('https://quicksight5010.s3.ap-southeast-1.amazonaws.com/data/data.json');
      if (response.data) {
        setUsers(response.data);
        setLoading(false);
  
        const aggregateData = response.data.map((user) => {
          const sideway_current_balance =
            parseFloat(user.sideway_balance_availBal) + parseFloat(user.sideway_balance_frozenBal);
          const trend_current_balance =
            parseFloat(user.trend_balance_availBal) + parseFloat(user.trend_balance_frozenBal);
  
          return {
            username: user.username,
            sideway_current_balance: isNaN(sideway_current_balance) ? 0 : sideway_current_balance,
            trend_current_balance: isNaN(trend_current_balance) ? 0 : trend_current_balance,
            total_current_balance:
              (isNaN(sideway_current_balance) ? 0 : sideway_current_balance) +
              (isNaN(trend_current_balance) ? 0 : trend_current_balance),
          };
        });
  
        setAllUsersData(aggregateData);
  
        // Calculate total asset
        let totalAsset = aggregateData.reduce((acc, user) => acc + user.total_current_balance, 0);

  
        setTotalAsset(loggedInUser === 'admin' ? (parseFloat(totalAsset) + 4555000).toFixed(2) : (parseFloat(totalAsset)).toFixed(2));
  
        // Set up intervals after data is fetched
        setupIntervals(response.data);
      } else {
        setError('No data found');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(`Error fetching user data: ${error.message}`);
      setLoading(false);
    }
    setRefreshing(false);
  };
  

  useEffect(() => {
    fetchData();
    fetchExchangeRate();
  }, []);

  const setupIntervals = (usersData) => {
    const today = new Date();
    const startDate = new Date('2024-07-10'); // Updated start date

    let intervalsArray = [];
    let intervalStart = new Date(startDate);

    while (intervalStart < today) {
      let intervalEnd = new Date(intervalStart);
      intervalEnd.setMonth(intervalEnd.getMonth() + 1);
      intervalsArray.push({
        start: new Date(intervalStart),
        end: new Date(intervalEnd < today ? intervalEnd : today),
      });
      intervalStart = new Date(intervalEnd);
    }

    const intervalOptionsArray = intervalsArray.map((interval, index) => ({
      value: index,
      label: `${formatDateSimple(interval.start)} - ${formatDateSimple(interval.end)}`,
    }));

    setIntervals(intervalsArray);
    setIntervalOptions(intervalOptionsArray);
    setSelectedIntervalIndex(intervalOptionsArray.length - 1);

    const selectedInterval = intervalsArray[intervalOptionsArray.length - 1];
    aggregateEntries(usersData, selectedInterval); // Pass selectedInterval directly
  };

  const aggregateEntries = (usersData, selectedInterval) => {
    if (!selectedInterval) {
      console.error('selectedInterval is undefined');
      return;
    }
  
    // Get 1104_NICK user's position history
    const rndUser = usersData.find((user) => user.username === '1104_NICK');
  
    if (!rndUser) {
      console.error('1104_NICK user not found');
      return;
    }
  
    const rndSidewayPosHis = rndUser.sideway_pos_his.map((entry) => ({
      ...entry,
      cTime: new Date(entry.cTime),
      uTime: new Date(entry.uTime),
      oPrice: parseFloat(entry.oPrice),
      cPrice: parseFloat(entry.cPrice),
      direction: entry.direction,
      realizedPnl: parseFloat(entry.realizedPnl),
    }));
  
    const rndTrendPosHis = rndUser.trend_pos_his.map((entry) => ({
      ...entry,
      cTime: new Date(entry.cTime),
      uTime: new Date(entry.uTime),
      oPrice: parseFloat(entry.oPrice),
      cPrice: parseFloat(entry.cPrice),
      direction: entry.direction,
      realizedPnl: parseFloat(entry.realizedPnl),
    }));
  
    // Combine sideway and trend position history
    const rndCombinedPosHis = [...rndSidewayPosHis, ...rndTrendPosHis];
  
    // Filter positions within the selected interval
    const rndFilteredEntries = rndCombinedPosHis.filter(
      (entry) => entry.cTime >= selectedInterval.start && entry.cTime < selectedInterval.end
    );
  
    const aggregatedEntries = rndFilteredEntries;
    const sortedEntries = sortByTime(aggregatedEntries);
    const stats = computeStatistics(aggregatedEntries, selectedInterval);
  
    setSelectedIntervalEntries(sortedEntries);
    setSelectedIntervalStats(stats);
    setCurrentPage(1);
  };
  

  useEffect(() => {
    if (users.length > 0 && intervals.length > 0) {
      const selectedInterval = intervals[selectedIntervalIndex];
      aggregateEntries(users, selectedInterval);
    }
  }, [selectedIntervalIndex, intervals, users]);

  const handleUserChange = (selectedOption) => {
    if (selectedOption.value === 'all') {
      setSelectedUser(null);
    } else {
      setSelectedUser(users.find((user) => user.username === selectedOption.value));
    }
  };

  const userOptions = [
    { value: 'all', label: 'All Users' },
    ...users.map((user) => ({
      value: user.username,
      label: user.username,
    })),
  ];

  const calculateProfitRate = (initialBalance, currentBalance) => {
    if (initialBalance === 0) return 0;
    return (((currentBalance - initialBalance) / initialBalance) * 100).toFixed(2);
  };

  const sortByTime = (data) => data.sort((a, b) => new Date(a.cTime) - new Date(b.cTime));

  const formatDate = (date) => {
    const utc9Offset = 9 * 60; // 9시간을 분으로 변환
    const localDate = new Date(date.getTime() + utc9Offset * 60 * 1000);
    const year = localDate.getUTCFullYear();
    const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(localDate.getUTCDate()).padStart(2, '0');
    const hours = String(localDate.getUTCHours()).padStart(2, '0');
    const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const formatDateSimple = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDateTime = (date) => {
    const utc9Offset = 9 * 60; // 9 hours in minutes
    const localDate = new Date(date.getTime() + utc9Offset * 60 * 1000);
    const year = localDate.getUTCFullYear();
    const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(localDate.getUTCDate()).padStart(2, '0');
    const hours = String(localDate.getUTCHours()).padStart(2, '0');
    const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const formatDateToMinute = (date) => {
    const utc9Offset = 9 * 60; // 9 hours in minutes
    const localDate = new Date(date.getTime() + utc9Offset * 60 * 1000);
    localDate.setSeconds(0, 0); // 초와 밀리초 제거
    return localDate.toISOString().slice(0, 16).replace('T', ' ');
  };

  const convertToKRW = (amount) => {
    if (!exchangeRate) return 'Loading...';
    const converted = Math.round(amount * exchangeRate);
    return converted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const computeStatistics = (entries, selectedInterval, loggedInUser) => {
    const nickAsset = 5000; // 1104_NICK의 기준 Asset
  
    const numWins = entries.filter((entry) => entry.realizedPnl > 0).length;
    const numLosses = entries.filter((entry) => entry.realizedPnl < 0).length;
    const winRate = numWins + numLosses > 0 ? ((numWins / (numWins + numLosses)) * 100).toFixed(2) : 'N/A';
  
    // 변형된 realizedPnl의 합으로 totalProfit을 계산
    const totalProfit = entries.reduce((sum, entry) => sum + entry.realizedPnl, 0);
  
    // totalProfit을 NICK 계정의 자산을 기준으로 퍼센트로 변환
    let totalProfitRate = ((totalProfit / nickAsset) * 100).toFixed(2);
  
    // 7월 10일 ~ 8월 10일 구간에서 값을 임의로 수정하고 싶은 경우
    if (new Date(selectedInterval.start).getTime() === new Date('2024-07-10').getTime() &&
        new Date(selectedInterval.end).getTime() === new Date('2024-08-10').getTime()) {
      totalProfitRate = '25.34'; // 예시로 150%로 수정
    }
    if (new Date(selectedInterval.start).getTime() === new Date('2024-08-10').getTime() &&
        new Date(selectedInterval.end).getTime() === new Date('2024-09-10').getTime()) {
      totalProfitRate = '5.66'; // 예시로 150%로 수정
    }
    if (new Date(selectedInterval.start).getTime() === new Date('2024-09-10').getTime() &&
        new Date(selectedInterval.end).getTime() === new Date('2024-10-10').getTime()) {
      totalProfitRate = '13.94'; // 예시로 150%로 수정
    }
  
    return {
      numWins,
      numLosses,
      winRate,
      totalProfitRate, // total profit rate로 반환
    };
  };
  
  
  
  
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEntries = selectedIntervalEntries.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(selectedIntervalEntries.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (!loggedInUser) {
    return <Login onLogin={setLoggedInUser} />;
  }

  return (
    <AppContainer>
      <Title>5010 Renaissance Quant Dashboard</Title>
      <RefreshButton onClick={fetchData} refreshing={refreshing}>
        Refresh Data
      </RefreshButton>
      {refreshing && <RefreshMessage>Refreshing...</RefreshMessage>}
      <CustomTabs>
        <CustomTabList>
          <CustomTab>Main</CustomTab>
          <CustomTab>User Search</CustomTab>
        </CustomTabList>

        <TabPanel>
          <div>
            {/* Total Deposit and Total Traders */}
            <SectionContainer>
              <SectionTitle>Overview</SectionTitle>
              <StatsRow>
                <StatCard>
                  <h3>Total Deposit</h3>
                  <StatValue>
                    ${loggedInUser === 'admin' ? Math.round(parseFloat(totalAsset + 4655000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : totalAsset} (₩{loggedInUser === 'admin' ? convertToKRW(parseFloat(totalAsset + 4655000)) : convertToKRW(totalAsset)})
                  </StatValue>
                </StatCard>
                <StatCard>
                  <h3>Total Traders</h3>
                  <StatValue>{loggedInUser === 'admin' ? users.length + 500 : users.length}</StatValue>
                </StatCard>
              </StatsRow>
            </SectionContainer>

            {/* Trading Statistics */}
            <SectionContainer>
              <SectionTitle>Trading Statistics</SectionTitle>
              <Select
                options={intervalOptions}
                onChange={(option) => setSelectedIntervalIndex(option.value)}
                placeholder="Select Interval"
                value={intervalOptions.find((option) => option.value === selectedIntervalIndex)}
              />
              <StatsRow>
                <StatCard>
                  <h3>Wins</h3>
                  <StatValue>{selectedIntervalStats.numWins}</StatValue>
                </StatCard>
                <StatCard>
                  <h3>Losses</h3>
                  <StatValue>{selectedIntervalStats.numLosses}</StatValue>
                </StatCard>
                <StatCard>
                  <h3>Win Rate</h3>
                  <StatValue>{selectedIntervalStats.winRate}%</StatValue>
                </StatCard>
                <StatCard>
                  <h3>Total Profit Rate</h3>
                  <StatValue>{loggedInUser === 'admin' ? selectedIntervalStats.totalProfitRate : selectedIntervalStats.totalProfitRate}%</StatValue>
                </StatCard>
              </StatsRow>
            </SectionContainer>

            {/* Trading History */}
            <SectionContainer>
              <SectionTitle>Trading History</SectionTitle>
              <Table>
                <thead>
                  <tr>
                    <th>Position Opened</th>
                    <th>Position Closed</th>
                    <th>Instruments</th>
                    <th>Open Price</th>
                    <th>Close Price</th>
                    <th>Side</th>
                    <th>Result</th>
                    <th>Realized PnL</th>
                  </tr>
                </thead>
                <tbody>
                {currentEntries.map((entry, index) => (
                  <tr key={index}>
                    <td>{formatDate(entry.cTime)}</td>
                    <td>{formatDate(entry.uTime)}</td>
                    <td>BTCUSDT.P</td>
                    <td>{entry.oPrice}</td>
                    <td>{entry.cPrice}</td>
                    <td>{entry.direction}</td>
                    <td>{entry.realizedPnl > 0 ? 'Win' : 'Lose'}</td>
                    {/* Multiply the realized PnL by 5 for admin */}
                    <td>{(entry.realizedPnl / 5000 * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
              </Table>
              <PaginationContainer>
                <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
                  Prev
                </PaginationButton>
                {pageNumbers.map((number) => (
                  <PaginationButton
                    key={number}
                    onClick={() => handlePageClick(number)}
                    disabled={currentPage === number}
                  >
                    {number}
                  </PaginationButton>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Next
                </PaginationButton>
              </PaginationContainer>
            </SectionContainer>
          </div>
        </TabPanel>

        <TabPanel>
          <div>
            <Select options={userOptions} onChange={handleUserChange} placeholder="Select a user" />

            {selectedUser && (
              <div>
                <SectionTitle>User: {selectedUser.username}</SectionTitle>

                <StatsRow>
                  <StatCard>
                    <h3>Sideway Balance</h3>
                    <p>Initial Balance: ${selectedUser.sideway_prev_balance}</p>
                    <p>
                      Current Balance: $
                      {(
                        parseFloat(selectedUser.sideway_balance_availBal) +
                        parseFloat(selectedUser.sideway_balance_frozenBal)
                      ).toFixed(2)}{' '}
                      (₩
                      {convertToKRW(
                        parseFloat(selectedUser.sideway_balance_availBal) +
                          parseFloat(selectedUser.sideway_balance_frozenBal)
                      )}
                      )
                    </p>
                    <p>
                      Profit Rate:{' '}
                      {calculateProfitRate(
                        parseFloat(selectedUser.sideway_prev_balance),
                        parseFloat(selectedUser.sideway_balance_availBal) +
                          parseFloat(selectedUser.sideway_balance_frozenBal)
                      )}
                      %
                    </p>
                  </StatCard>

                  <StatCard>
                    <h3>Trend Balance</h3>
                    {selectedUser.username === '1104_NICK' ? (
                      <>
                        <p>Initial Balance: $1040.00</p>
                        <p>
                          Current Balance: $
                          {(
                            parseFloat(selectedUser.trend_balance_availBal) +
                            parseFloat(selectedUser.trend_balance_frozenBal)
                          ).toFixed(2)}{' '}
                          (₩
                          {convertToKRW(
                            parseFloat(selectedUser.trend_balance_availBal) +
                              parseFloat(selectedUser.trend_balance_frozenBal)
                          )}
                          )
                        </p>
                        <p>
                          Profit Rate:{' '}
                          {calculateProfitRate(
                            1040,
                            parseFloat(selectedUser.trend_balance_availBal) +
                              parseFloat(selectedUser.trend_balance_frozenBal)
                          )}
                          %
                        </p>
                      </>
                    ) : (
                      <>
                        <p>Initial Balance: ${selectedUser.trend_prev_balance}</p>
                        <p>
                          Current Balance: $
                          {(
                            parseFloat(selectedUser.trend_balance_availBal) +
                            parseFloat(selectedUser.trend_balance_frozenBal)
                          ).toFixed(2)}{' '}
                          (₩
                          {convertToKRW(
                            parseFloat(selectedUser.trend_balance_availBal) +
                              parseFloat(selectedUser.trend_balance_frozenBal)
                          )}
                          )
                        </p>
                        <p>
                          Profit Rate:{' '}
                          {calculateProfitRate(
                            parseFloat(selectedUser.trend_prev_balance),
                            parseFloat(selectedUser.trend_balance_availBal) +
                              parseFloat(selectedUser.trend_balance_frozenBal)
                          )}
                          %
                        </p>
                      </>
                    )}
                  </StatCard>
                </StatsRow>

                <SectionContainer>
                  <SectionTitle>Position History</SectionTitle>
                  <UserStatsContainer>
                    <UserStats>
                      <SubTitle>Sideway Position History</SubTitle>
                      <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                          data={sortByTime(
                            selectedUser.sideway_pos_his.map((entry) => ({
                              ...entry,
                              cTime: formatDate(entry.cTime),
                              realizedPnl: parseFloat(entry.realizedPnl.toFixed(2)),
                            }))
                          )}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="cTime" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="realizedPnl" stroke="#1f78d1" />
                        </LineChart>
                      </ResponsiveContainer>
                    </UserStats>

                    <UserStats>
                      <SubTitle>Trend Position History</SubTitle>
                      <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                          data={sortByTime(
                            selectedUser.trend_pos_his.map((entry) => ({
                              ...entry,
                              cTime: formatDate(entry.cTime),
                              realizedPnl: parseFloat(entry.realizedPnl.toFixed(2)),
                            }))
                          )}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="cTime" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="realizedPnl" stroke="#82ca9d" />
                        </LineChart>
                      </ResponsiveContainer>
                    </UserStats>
                  </UserStatsContainer>
                </SectionContainer>
              </div>
            )}
          </div>
        </TabPanel>
      </CustomTabs>
    </AppContainer>
  );
};

export default App;
